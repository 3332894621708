import { Head } from 'components/Head';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import LogoDefault from 'assets/logo-default.svg';
import AppleStore from 'assets/apple_store.svg';
import PlayStore from 'assets/play_store.svg';
import Connect from 'assets/connect.svg';
import ConnectWithLocals from 'assets/connect_with_locals.svg';
import Explore from 'assets/explore.svg';
import OrderRides from 'assets/order_rides.svg';
import Mockup from 'assets/mockup.jpg';
import { Link } from 'react-router-dom';
import { Accordion } from 'components/Elements';
import { Logo } from 'components/Layout/components/Logo';

export const Landing = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Head title="JustKnow" description="Connect Locally, Live Freely" />
      <header className="londrina-solid-regular">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="h-16 flex md:block justify-between md:my-8 md:mx-12">
            <div className="flex flex-row items-center justify-between">
              <div className="flex-shrink-0">
                {/* Logo */}
                <img className="h-12 w-auto" src={LogoDefault} alt="JustKnow Logo" />
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {/* Desktop NavLinks */}
                <a
                  href="#about"
                  className="text-gray-900 inline-flex text-lg items-center px-1 pt-1 border-b-2 border-transparent hover:border-indigo-500"
                >
                  About
                </a>
                <a
                  href="#blog"
                  className="text-gray-900 inline-flex text-lg items-center px-1 pt-1 border-b-2 border-transparent hover:border-indigo-500"
                >
                  Our Blog
                </a>
                <a
                  href="#join"
                  className="text-gray-900 inline-flex text-lg items-center px-1 pt-1 border-b-2 border-transparent hover:border-indigo-500"
                >
                  Join Us
                </a>
                <a
                  href="#contact"
                  className="text-white bg-[#FF6937] rounded-full inline-flex items-center px-4 py-2 border-b-2 border-transparent hover:border-indigo-500"
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
              >
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path
                    className={!isMenuOpen ? 'block' : 'hidden'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                  <path
                    className={isMenuOpen ? 'block' : 'hidden'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              <a
                href="#about"
                className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50"
              >
                About
              </a>
              <a
                href="#blog"
                className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300"
              >
                Our Blog
              </a>
              <a
                href="#join"
                className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300"
              >
                Join Us
              </a>
              <a
                href="#contact"
                className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300"
              >
                Contact Us
              </a>
            </div>
          </div>
        )}
      </header>

      <div className="londrina-solid-regular grid grid-cols-1 md:grid-cols-2 gap-4 mx-8 md:mx-32 mb-12">
        <div className="my-24">
          <div className="text-6xl font-bold mb-2">Connect Locally</div>
          <div className="text-6xl font-bold mb-2">Live Freely</div>
          <div className="text-xl mb-3">Chat, Ride, and Discover – All within Your Radius.</div>
          <div className="flex flex-row items-center space-x-2">
            <a href="">
              <img src={AppleStore} alt="App Store" className="h-12" />
            </a>
            <a href="">
              <img src={PlayStore} alt="Play Store" className="h-12" />
            </a>
          </div>
        </div>

        <div className="">
          <img src={Connect} alt="Connect locally" />
        </div>
      </div>

      <div className="mb-12 grid grid-cols-1 md:grid-cols-2 gap-8 mx-8 md:mx-24 md:items-center">
        <img src={Mockup} alt="JK Mockup" className="h-48 md:h-[60vh] mx-auto" />
        <div className="londrina-solid-black text-4xl md:text-6xl text-center">
          Order a ride and stay updated with your JustKnow feed.
        </div>
      </div>

      <div className="max-w-7xl mx-8 md:mx-32 mb-24 ">
        <div className="text-4xl font-bold text-center mb-4 londrina-solid-black">Our Services</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="">
            <div className="border bg-[#FFEFEA] rounded-2xl border-[#000000] p-8 mb-4">
              <img src={ConnectWithLocals} alt="Chat with nearby users" className="mx-auto mb-8" />
              <div className="text-center font-bold text-xl londrina-solid-light">
                Chat with nearby users
              </div>
            </div>
            <div className="text-2xl font-bold text-center londrina-solid-bold">
              CONNECT WITH LOCALS
            </div>
          </div>
          <div className="">
            <div className="border bg-[#FFEFEA] rounded-2xl border-[#000000] p-8 mb-4">
              <img src={ConnectWithLocals} alt="Chat with nearby users" className="mx-auto mb-8" />
              <div className="text-center font-bold text-xl londrina-solid-light">
                Flexible Boda Rides
              </div>
            </div>
            <div className="text-2xl font-bold text-center londrina-solid-bold">
              ORDER RIDES, YOUR WAY
            </div>
          </div>
          <div className="">
            <div className="border bg-[#FFEFEA] rounded-2xl border-[#000000] p-8 mb-4">
              <img src={ConnectWithLocals} alt="Chat with nearby users" className="mx-auto mb-8" />
              <div className="text-center font-bold text-xl londrina-solid-light">
                Discover Local Businesses{' '}
              </div>
            </div>
            <div className="text-2xl font-bold text-center londrina-solid-bold">
              EXPLORE WHAT’S NEARBY
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-8 md:mx-32 mb-12 ">
        <div className="text-4xl font-bold text-center mb-4 londrina-solid-black">
          Join Us Today
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Link to="/register-your-business">
            <div className="bg-[#FFEFEA] rounded-2xl p-8 mb-4">
              <img src={Explore} alt="Chat with nearby users" className="mx-auto mb-8 h-48" />
              <div className="text-2xl font-bold text-center londrina-solid-bold">
                Register Your Business
              </div>
            </div>
          </Link>
          <Link to="/become-a-rider">
            <div className="bg-[#FFEFEA] rounded-2xl p-8 mb-4">
              <img src={OrderRides} alt="Chat with nearby users" className="mx-auto mb-8 h-48" />
              <div className="text-2xl font-bold text-center londrina-solid-bold">
                Become A Rider
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="mx-8 md:mx-32 mb-12">
        <div className="text-4xl font-bold text-start mb-4 londrina-solid-black">Why JustKnow</div>
        <Accordion title="Hyper-Local Connectivity">
          <div className="">
            JustKnow empowers you to connect with people in your immediate vicinity. Whether you're
            looking to make new friends, join local communities, or stay updated on what's happening
            around you, our app makes it easy to engage with those nearby
          </div>
        </Accordion>
        <Accordion title="Customizable Boda Rides">
          <div className="">
            With JustKnow, you're in control of your ride. Set your own starting price, track your
            ride in real-time, and share your journey with others for added security. No more
            haggling – just safe, affordable rides on your terms.
          </div>
        </Accordion>
        <Accordion title="Easy to Use">
          <div className="">
            JustKnow’s user-friendly interface ensures that you can navigate the app with ease.
            Whether you’re chatting, ordering a ride, or searching for a business, everything is
            just a tap away.
          </div>
        </Accordion>
        <Accordion title="Secure and Reliable">
          <div className="">
            Your safety is our priority. With robust security features, real-time tracking, and the
            ability to share your location, JustKnow ensures that your experiences are not only
            convenient but also secure.
          </div>
        </Accordion>
      </div>

      <div className="mb-12 mx-8 md:mx-32 londrina-solid-thin">
        <div className="flex flex-row items-center justify-center mb-8">
          <Logo />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="">
            <div className="text-lg londrina-solid-black mb-4">Get In Touch</div>
            <div className="flex flex-col space-y-3">
              <div className="">Plot 593 off Muganzi Awongererwa Road, Makerere, Kampala</div>
              <div className="">+256 782692271</div>
              <div className="">hello@justknow.ug</div>
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Our Blog
              </Link>
            </div>
          </div>

          <div className="">
            <div className="text-lg londrina-solid-black mb-4">Join JustKnow</div>
            <div className="flex flex-col space-y-3">
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Careers
              </Link>
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Join as a rider
              </Link>
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Register Business
              </Link>
            </div>
          </div>

          <div className="">
            <div className="text-lg londrina-solid-black mb-4">Support</div>
            <div className="flex flex-col space-y-3">
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Terms of Service
              </Link>
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Privacy Policy
              </Link>
              <Link to="/our-blog" className="underline hover:text-[#FF6937]">
                Account Deletion Policy
              </Link>
            </div>
          </div>

          <div className="">
            <div className="text-lg londrina-solid-black mb-4">Download App</div>
            <div className="flex flex-col space-y-3">
              <a href="" target="_blank" to="/our-blog" className="underline hover:text-[#FF6937]">
                <img src={AppleStore} alt="Apple Store" />
              </a>
              <a href="" target="_blank" to="/our-blog" className="underline hover:text-[#FF6937]">
                <img src={PlayStore} alt="Play Store" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright North Up limited */}
      <div className="mb-12">
        <div className="londrina-solid-thin text-md text-center">
          © JustKnow 2024, All rights reserved.
        </div>
      </div>
    </>
  );
};
